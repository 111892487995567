<template>
  <div :style="invitationLabelStyle">
    <span>{{ invitationStatus.text }}</span>
    <a
      v-if="invitationStatus.resendPossible"
      :style="resendLinkStyle"
      @click="$emit('resend')"
    >
      Resend
    </a>
  </div>
</template>

<script>
export default {
  name: "InvitationStatusLabel",
  props: {
    isEmailVerified: Boolean,
    isInvitationExpired: Boolean,
  },
  computed: {
    invitationStatus() {
      return this.getInvitationStatus(
        this.isEmailVerified,
        this.isInvitationExpired
      );
    },
    invitationLabelStyle() {
      return {
        backgroundColor: this.invitationStatus.color,
        color: "white",
        padding: "5px 20px",
        borderRadius: "25px",
        textAlign: "center",
        border: `1px solid ${this.invitationStatus.color}`,
        display: "inline-block",
      };
    },
    resendLinkStyle() {
      return {
        marginLeft: "10px",
        fontSize: "14px",
        color: "#5B3D87",
      };
    },
  },
  methods: {
    getInvitationStatus(isEmailVerified, isInvitationExpired) {
      if (isEmailVerified) {
        return {
          text: "Email Verified",
          color: "#70D0CE",
          resendPossible: false,
        };
      } else if (isInvitationExpired) {
        return {
          text: "Invitation Expired",
          color: "#EA7A66",
          resendPossible: true,
        };
      } else {
        return {
          text: "Invitation Pending",
          color: "#C2AAE8",
          resendPossible: true,
        };
      }
    },
  },
};
</script>
